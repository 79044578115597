



function radioShowHideFields($field) {
  $section = $field.closest('.toggleshowcontainer');
  if ($section.length === 0) {
    $section = $field.closest('form');
  }

  console.log($field.data('showtarget'));
  var field_val = $('input[name="'+$field.attr('name')+'"]:checked').val();
  // console.log(field_val);
  // console.log($field.val());
  $target = $section.find($field.data('showtarget'));

  if ($field.data('showif') && (String(field_val) === String($field.data('showif')))) {
    // console.log('show!');
    // console.log($target);
    $target.show();
  } else {
    // console.log('hide!');
    // console.log($target);
    $target.hide();
  }
}

function radioHideShowFields($field) {
  $section = $field.closest('.toggleshowcontainer');
  if ($section.length === 0) {
    $section = $field.closest('form');
  }

  var field_val = $('input[name="'+$field.attr('name')+'"]:checked').val();
  // console.log($field.data('hidetarget'));
  // console.log(field_val);
  $target = $section.find($field.data('hidetarget'));

  if ($field.data('hideif') && (String(field_val) === String($field.data('hideif')))) {
    // console.log('hide!');
    // console.log($target);
    $target.hide();
  } else {
    // console.log('show!');
    // console.log($target);
    $target.show();
  }
}

$(document).on('change', 'input[data-behaviour~=toggleshow][type=radio]', function(evt) {
  radioShowHideFields($(evt.target));
});
$(document).on('change', 'input[data-behaviour~=togglehide][type=radio]', function(evt) {
  radioHideShowFields($(evt.target));
});

$(document).on('turbolinks:load', function() {
  $('input[data-behaviour~=toggleshow][type=radio]').each(function(idx) {
    radioShowHideFields($(this));
  });
  $('input[data-behaviour~=togglehide][type=radio]').each(function(idx) {
    radioHideShowFields($(this));
  });

  $('.business_type_other_row').hide();
  $('input#user_current_team_attributes_business_type_other').on('change',function() {
    console.log('changed');
    console.log($('input#user_current_team_attributes_business_type_other').is(':checked'));
    if ($('input#user_current_team_attributes_business_type_other').is(':checked')) {
      $('.business_type_other_row').show();
    } else {
      $('.business_type_other_row').hide();
    }
  });
  $('input#team_business_type_other').on('change',function() {
    console.log('changed');
    console.log($('input#team_business_type_other').is(':checked'));
    if ($('input#team_business_type_other').is(':checked')) {
      $('.business_type_other_row').show();
    } else {
      $('.business_type_other_row').hide();
    }
  });

})

$(document).on('sprinkles:update', function(event) {
  $('input[data-behaviour~=toggleshow][type=radio]').each(function(idx) {
    radioShowHideFields($(this));
  });
  $('input[data-behaviour~=togglehide][type=radio]').each(function(idx) {
    radioHideShowFields($(this));
  });
})


$(document).on("change", "select[data-behaviour~=manufacturerselector]", function(evt) {
  var manufacturer_id = $(this).val();
  var model_select = $(this).closest("fieldset").find("select[data-behaviour~=modelselector]");

  if (model_select.length > 0) {
    model_select.empty();
    model_select.append($("<option>", {value: "", text: "Select Model..."}));
    model_select.prop("disabled", manufacturer_id === "");
  }

  if (manufacturer_id === "") {
    var manufacturer_name = $(this).find("option:selected").text();
    if (manufacturer_name === "Other") {
      $(".other-manufacturer").show();
      model_select.prop("disabled", false);
    } else {
      $(".other-manufacturer").hide();
    }
  } else {
    $(".other-manufacturer").hide();
  }

  if (model_select.length > 0) {
    var models = window.models_by_manufacturer_id[manufacturer_id];
    $.each(models, function(i, model) {
      model_select.append($("<option>", {value: model.id, text: model.name}));
    });
    model_select.append($("<option>", {value: "", text: "Other"}));
    model_select.trigger("change");
  }
});

$(document).on("change", "select[data-behaviour~=modelselector]", function(evt) {
  var model_name = $(this).find("option:selected").text();
  if (model_name === "Other") {
    $(".other-model").show();
  }
  else {
    $(".other-model").hide();
  }
});

$(document).on('change', 'select[data-behaviour~=categoryselector]', function(evt) {
  console.log('changed');
  path = location.pathname + '?category_id=' + $(this).val();
  console.log(path);
  Turbolinks.visit(path)
});

$(document).on('turbolinks:load', function() {
  if ($("#machinery_fuel_fieldset").length > 0) {

    $("#machinery_fuel_interaction_type_uses").siblings("button").on("click", function() {
      $("#fuel_common_fields").show();
      $("#fuel_container_fields").hide();
    })

    $("#machinery_fuel_interaction_type_stores").siblings("button").on("click", function() {
      $("#fuel_common_fields").show();
      $("#fuel_container_fields").show();
    })

    $("#machinery_fuel_interaction_type_").siblings("button").on("click", function() {
      $("#fuel_common_fields").hide();
      $("#fuel_container_fields").hide();
    })

    $("#machinery_source_tank_type").on("change", function(event) {
      if (event.target.value === "mother_tank") {
        $("#source_tank_location").show();
      } else {
        $("#source_tank_location").hide();
      }
    })
  }

})
